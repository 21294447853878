import React from 'react';
import { graphql } from 'gatsby';

import ParagraphComponent from '@core/helpers/paragraph-helpers';
import { isContentTypeHero } from '@core/helpers/hero';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import { Graphql } from '@models/graphql';

const { default: crumbs } = require('@config/breadcrumbs');

type ExpertiseAreaProps = {
  language: string,
  data: {
    expertiseArea: Graphql
  };
};

export default function ExpertiseAreaPage(
  { data, language }: ExpertiseAreaProps
): React.ReactElement {
  const { expertiseArea } = data;
  const paragraphs = expertiseArea?.relationships?.paragraphs || [];

  if (paragraphs.length <= 0) {
    return <></>;
  }

  const content = paragraphs.map(
    (node) => ParagraphComponent({ ...node, path: expertiseArea.path }, language)
  );

  const hero = isContentTypeHero(paragraphs[0]) ? content.shift() : <></>;

  return (
    <div className="area-expertise-container">
      {hero}
      <Breadcrumb crumbs={crumbs.concat([{ label: expertiseArea.title, link: '#' }])} />
      <div className="page-components-container">{content}</div>
    </div>
  );
}

export const query = graphql`
query($id: String!) {
  expertiseArea: nodeExpertiseArea(id: { eq: $id }) {
    title
    body {
      processed
      summary
    }
    path {
      alias
      langcode
    }
    relationships {
      paragraphs: field_content_main {
        type: __typename
        ...CtaParagraph
        ...HeroParagraph
        ...HeroSliderParagraph
        ...BannerParagraph
        ...BannerTalentParagraph
        ...BannerAdvParagraph
        ...QuoteSliderParagraph
        ...QuoteParagraph
        ...IntroTextParagraph
        ...IntroBlockParagraph
        ...TimelineParagraph
        ...CardsParagraph
        ...BoxesParagraph
        ...GridParagraph
        ...ClientsParagraph
        ...TechnologiesParagraph
        ...TabsParagraph
        ...TableParagraph
        ...WysiwygParagraph
        ...ViewParagraph
        ...FeaturedContentParagraph
        ...BulletsParagraph
        ...BannerFormParagraph
        ...BannerTalentApplyParagraph
        ...EmergencyModalParagraph
        ...BannerVideoParagraph
        ...WebFormParagraph
        ...CollageParagraph
        ...FabParagraph
        ...ImageSliderParagraph
        ...OfficesParagraph
      }
    }
  }
}`;
